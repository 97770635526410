@import "_variables";
@import '_mixins';

.edit-group-container {
  margin-top: 60px;
  padding: 16px 0px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  .ant-typography{
    border-bottom: 1px solid #E9EAF0;
    margin-bottom: 0px;
    padding-bottom: 16px;
    padding-left: 4px;
    padding-right: 0px;
    margin-block-start: 0px;
    .editGroup{
      margin-top: 0px;
      font-weight: "medium";
    }
  }
    .edit-group-form {
      background: #fff;
      padding: 20px;
      border-radius: 8px;
      .currencyInput{
        border: 1px solid #d9d9d9;
        padding: 16px;
        font-size: 18px;
        height: 62px;
        width: 350px;
      }
      .repGroup{
        .ant-form-item-row{
          // flex-flow: unset;
          display: flex;
          flex-direction: row-reverse;
          justify-content: center;
          width: 180px;
          @include media-breakpoint-down('md') {
            // flex-flow: unset;
            // width: 120px;
            // flex-direction: row-reverse;
          }
          .ant-form-item-label{
            padding: 0px;
            white-space: unset;
            width: 1100px;
            padding-top: 5px;
            padding-left: 4px;
            margin: 0px;
           
          }
        }
      }
      .ant-form-item {
        margin-bottom: 16px;
      }
  
      .ant-btn-primary {
        margin-top: 10px;
      }
    }
  }
  
  .ant-drawer-content-wrapper {
    max-width: 400px;
  }
  
  .ant-list-item {
    display: flex;
    align-items: center;
    
    // .ant-checkbox-wrapper {
    //   margin-left: auto;
    // }
  }
  