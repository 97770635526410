.signup-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-top: 30px;
  }
  
  .signup-form .ant-form-item {
    margin-bottom: 16px;
  }
  
  .signup-form .ant-form-item-label {
    text-align: left;
  }
  
  .signup-form .ant-form-item-control {
    line-height: initial;
  }
  
  .signup-form .ant-btn {
    width: 100%;
  }
  