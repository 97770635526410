// /styles/OrdersTable.scss

.orders-table-container {
  .ant-table {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    .ant-table-thead > tr > th {
      
      
      font-weight: 500;
      padding: 16px;
      text-align: left;
    }

    .ant-table-tbody > tr > td {
      padding: 16px;
      color: #495057;

      &:first-child {
        display: flex;
        align-items: center;
        
        .icon {
          margin-right: 8px;
        }
      }

      &:last-child {
        text-align: right;
        font-weight: 600;
        color: #28a745;
      }
    }
 
    .ant-table-tbody > tr:hover {
      background-color: #e9ecef;
      cursor: pointer;
    }

    .clickable-row {
      &:hover {
        background-color: #f1f3f5;
      }
    }

    .amount-positive {
      color: #28a745;
    }
  }
  :where(.css-dev-only-do-not-override-qb2vf8).ant-table-wrapper{
    padding: 10px;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 20px;
    .ant-spin-nested-loading{
      .ant-spin-container{
        .ant-table{
          .ant-table-container{
            .ant-table-content{
              table{
                thead{
                  tr{
                    th{
                      background-color: white;
                      border-bottom: 1px solid #eae3e3;
                      .ant-table-column-sorters{
                        .ant-table-column-title{
                          color: #718EBF;
                        }
                      }
                    }
                  }
                }
                tbody{
                  tr:last-child{
                    td {
                      border-bottom: none;
                    }
                  }
                }
              }
            }

          }
        }
      }
    }
    
  }
  
}