.product-list-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

}
.product-container{
  max-width: 1440px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  display: flex;
}

.product-card {
  border-radius: 5px;
  margin: 10px;
  width: 300px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(117, 116, 116, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;

  @media (max-width: 768px) {
    width: 90%;
  }

  .product-image {
    padding: 20px;
  }

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  img {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    border-radius: 5px 5px 0 0;
  }

  .product-details {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
    align-items: center;
 

    .product-name {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 8px;
      color: #333;
    
    }

    .product-description {
      position: relative;
      display: inline;
      text-decoration: none;
      color: #000;
      padding:10px;
    }
    .product-description::after {
      content: "";
      position: absolute;
      bottom: -2px; /* Adjust the gap size here */
      left: 0;
      width: 100%;
      border-bottom: 1px solid #000;
    }


    .product-price {
      padding: 10px;
      font-size: 18px;
      margin-bottom: 0px;
      color: black;
    }
    .type {
     
      margin-bottom: 10px;
      color: black;
    }
    
  }
  .button-container {
      display: flex;
      justify-content: space-between;


      .button {
        flex: 1;
        padding:10px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        transition: all 0.3s;
        cursor: pointer;
        text-align: center;
        width:45%;
        gap:10%;
        height:50px;
        justify-content: center;
        line-height: 1;

        &.view-product {
          text-decoration: none;
          background-color: #fff;
          color: #040303;
          border: 2px solid #000;
          border-radius: 8px;
          margin-right:10px;

          &:hover {
            background-color: #f0f0f0;
          }
        }

        &.add-to-cart {
          background-color: #ba1f1f;
          color: #fff;
          

          &:hover {
            background-color: #333;
          }
        }
      }
    }
}
.products-table-container {
  padding: 16px;
  overflow-x: auto;

  .ant-table {
    min-width: 600px; // Set a minimum width for the table

    // Handle responsive table styles
    @media (max-width: 768px) {
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        padding: 8px;
      }
    }
  }


  :where(.css-dev-only-do-not-override-zd39c6).ant-pagination .ant-pagination-item{
    border-radius: 4px;
  }
  :where(.css-dev-only-do-not-override-zd39c6).ant-table-wrapper .ant-table-footer{
    display: flex;
    justify-content: center;
  }
  img {
    //max-width: 100%;
    height: auto;
  }
}
