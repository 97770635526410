/* CatalogsTable.scss */

.clickable-row {
    cursor: pointer;
  }

  :where(.css-dev-only-do-not-override-zd39c6).ant-pagination .ant-pagination-item{
    border-radius: 4px;
  }
  :where(.css-dev-only-do-not-override-zd39c6).ant-table-wrapper .ant-table-footer{
    display: flex;
    justify-content: center;
  }