.add-group-container {
  margin-top: 60px;
  padding: 16px 0px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  .currencyInput{
    border: 1px solid #d9d9d9;
    padding: 16px;
    font-size: 18px;
    height: 62px;
    width: 350px;
  }
  .ant-typography{
    border-bottom: 1px solid #E9EAF0;
    margin-bottom: 0px;
    padding-bottom: 16px;
    padding-left: 20px;
    padding-right: 20px;
    margin-block-start: 0px;
    .editGroup{
      margin-top: 0px;
      font-weight: "medium";
    }
  }
    .add-group-form {
      background: #fff;
      padding: 20px;
      border-radius: 8px;

  

    }
  
  }
  