@import '_mixins';
.change-password-container {
  padding: 20px;
  background-color: white;
  border-radius: 25px;
  margin-top: 20px;

  @include media-breakpoint-down(sm) {
    padding: 1rem;
  }
}

.change-password-form {
  margin: 20px 40px;
  .ant-form-item-label {
    font-weight: 500;
  }
  .password-form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
    .current-password{
      width: 48%;
      @include media-breakpoint-down(md) {
        width: 100%;
         }
      
    }
    .new-password{
      width: 50%;
      @include media-breakpoint-down(md) {
        width: 100%;
         }
      
    }
  }



  .currencyInput{
    border: 1px solid #d9d9d9;
    padding: 16px;
    font-size: 18px;
    height: 62px;
  }
}
