.terms-container {
    margin: 2rem auto;
    padding: 2rem;
    max-width: 800px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    h1 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    p {
        font-size: 1rem;
        line-height: 1.6;
        margin-bottom: 1rem;
    }
}
