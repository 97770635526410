.add-to-cart-button {
  display: flex;
  align-items: center;
  width:45%;
  background-color: black;
  border-radius: 8px;
  color:white;
  justify-content: center;


  .action {
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #000;
    
   
    transition: background-color 0.3s;

   
  }

  span {
    display: flex;
    align-items: center;
  }
}
