.dashboard {
  display: flex;
  height: 100vh; /* Full height to make the content scrollable */
  :where(.css-dev-only-do-not-override-19f5tbr).ant-menu-inline .ant-menu-item, :where(.css-dev-only-do-not-override-19f5tbr).ant-menu-vertical .ant-menu-item, :where(.css-dev-only-do-not-override-19f5tbr).ant-menu-inline .ant-menu-submenu-title, :where(.css-dev-only-do-not-override-19f5tbr).ant-menu-vertical .ant-menu-submenu-title{
    border-radius: 0px;
  }
  :where(.css-dev-only-do-not-override-19f5tbr).ant-menu-inline .ant-menu-item, :where(.css-dev-only-do-not-override-19f5tbr).ant-menu-vertical .ant-menu-item, :where(.css-dev-only-do-not-override-19f5tbr).ant-menu-inline .ant-menu-submenu-title, :where(.css-dev-only-do-not-override-19f5tbr).ant-menu-vertical .ant-menu-submenu-title{
    margin-inline: 0px;
    width: 100%;
    
  }
  :where(.css-dev-only-do-not-override-19f5tbr).ant-menu-light .ant-menu-item-selected, :where(.css-dev-only-do-not-override-19f5tbr).ant-menu-light>.ant-menu .ant-menu-item-selected{
    border-right: 3px solid #C31532;
  }
  .sider {
    width: 200px;
    height: 100vh; /* Full height for fixed sidebar */
    position: fixed; /* Keeps the sidebar fixed on the screen */
    left: 0;
    top: 0;
    background: #001529; /* Ensure it covers the entire height */
    overflow: hidden; /* Prevents the sidebar from scrolling */
   
  }
  
  .content {
    margin-left: 200px; /* Adjust to the width of the sider */
    flex: 1;
    padding: 20px;
    overflow-y: auto; /* Allows the main content to be scrollable */
    height: 100vh; /* Matches the height of the screen */
  }
  .mainLayoutContent{
    flex: 1;
    padding: 20px;
    overflow-y: auto; /* Allows the main content to be scrollable */
    height: 100vh; /* Matches the height of the screen */
  }

  .dashboard-header {
    background: #fff;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    position: fixed; /* Fixes the header at the top */
    width: calc(100% - 200px); /* Adjusts for the sider width */
    top: 0;
    left: 200px; /* Aligns it with the content area */
    z-index: 1000; /* Ensures the header stays above other content */
    height: 64px; /* Standard header height */
  }
  .dashboard-header-mobile{
    background: #fff;
    padding: 0 24px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    position: fixed; /* Fixes the header at the top */
    width: calc(100%); /* Adjusts for the sider width */
    top: 0;
    z-index: 1000; /* Ensures the header stays above other content */
    height: 64px; /* Standard header height */
  }

  .add-product-button {
    background-color: #007bff;
    border-color: #007bff;

    &:hover {
      background-color: #0056b3;
      border-color: #0056b3;
    }
  }

  .header-content {
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .company-logo {
    height: 40px;
    margin-right: 16px;
  }

  .site-layout-content {
    background:#F5F5F5;
   // padding: 24px;
    margin-top: 64px; /* Adjust to account for the fixed header */
    min-height: calc(100vh - 64px); /* Ensures content area fills the space below the header */
  }
}
