@import "_variables";
@import '_mixins';




  .donation-header {
    font-size: 24px;
    margin-bottom: 20px;
  }


  .donation-button {
    background-color: red($color: #000000);
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s;

    .donation-icon {
      font-size: 12px;
      margin-right: 8px;
    }

    .add-donation {
      font-size: 10px;
      margin: 10px;
    }

    .donation-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 15px;
      background-color: rgb(10, 10, 10);
      border-radius: 8px;
      justify-content: center;

      .donation-amount {
        color: whitesmoke;
        font-size: 16px;
        font-weight: bold;
        display: block;
        @include media-breakpoint-down('xl') {
          font-size: 14px;
        }
        @include media-breakpoint-down('lg') {
          font-size: 12px;
        }
        @include media-breakpoint-down('md') {
          font-size: 12px;
        }
        @include media-breakpoint-down('sm') {
          font-size: 10px;
        }
      }

      .donation-add {
        background-color: black;
        border: none;
        border-radius: 5px;
        color: white;
        cursor: pointer;
        padding: 10px;
        text-transform: uppercase;
        transition: background-color 0.3s;

        &:hover {
          background-color: #555;
        }
        @include media-breakpoint-down('sm') {
          width: 100%;           // Make the button wider
          padding: 20px;         // Increase padding for larger buttons
          font-size: 18px;       // Increase font size for the text
        }
        @include media-breakpoint-down('xs') {
          width: 100%;           // Ensure full width on extra small screens
          padding: 22px;         // Further increase padding for smaller devices
          font-size: 20px;       // Further increase font size for better readability
        }
      }
    }

    @include media-breakpoint-down(md) {

      // width: 100%;
    }

    @include media-breakpoint-down(sm) {
      padding: 15px;

      
      .donation-button{
        width: 100px;
      }
      .donation-info {
     

        .donation-add {
          padding: 8px;
        }
      }
    }
  }

  .custom {
    .donation-input {
      border: none;
      border-radius: 5px;
      width: 150px;
      text-align: center;
      padding: 5px;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }



