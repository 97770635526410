@mixin media-breakpoint-down($breakpoint) {
  @if $breakpoint == 'sm' {
    @media (max-width: 576px) { @content; }
  } @else if $breakpoint == 'md' {
    @media (max-width: 768px) { @content; }
  } @else if $breakpoint == 'lg' {
    @media (max-width: 992px) { @content; }
  } @else if $breakpoint == 'xl' {
    @media (max-width: 1200px) { @content; }
  }
}
.select-group-for-user{

}
// :where(.css-dev-only-do-not-override-19f5tbr).ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-19f5tbr).ant-table-wrapper .ant-table-thead >tr>td{
//   vertical-align: baseline;
// }
.ant-select-show-search:where(.css-dev-only-do-not-override-zd39c6).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 0px;
  height: 45px;
}
.products-table-container {
  padding: 20px; /* Default padding */

  .clickable-row {
    cursor: pointer;
  }

  .description-column {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    vertical-align: middle;
  }

  @include media-breakpoint-down(sm) {
    padding: 10px;

    .description-column {
      max-width: 100px; /* Adjust as needed for small screens */
    }

    table {
      font-size: 12px; /* Reduce font size on small screens */
    }

    img {
      width: 50px;
      height: 50px;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 15px;

    .description-column {
      max-width: 150px; /* Adjust as needed for medium screens */
    }

    img {
      width: 75px;
      height: 75px;
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 20px;

    .description-column {
      max-width: 175px; /* Adjust as needed for large screens */
    }

    img {
      width: 90px;
      height: 90px;
    }
  }

  @include media-breakpoint-down(xl) {
    padding: 20px;

    .description-column {
      max-width: 200px; /* Adjust as needed for extra large screens */
    }

    img {
      width: 100px;
      height: 100px;
    }
  }
}
