@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import '_variables';
@import '_mixins';

body {
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
}
.ant-checkbox{
  .ant-checkbox-inner{
    border-radius: 3px;
    
  }
}

.ant-modal{
  .ant-modal-content{
    background-color: #f8f8f8;
   .ant-modal-header{
    background-color: #f8f8f8;
   }
  }
}
.item-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 10px 0;
  border-bottom: 1px solid #eee;

  .item-info {
    display: flex;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
      margin-right: 15px;
      border-radius: 6px;
      border: 1px solid #ddd;
    }

    h3 {
      margin: 0;
      font-size: 18px;
      color: #333;
    }

    p {
      margin: 0;
      font-size: 14px;
      color: #666;
    }
  }

  .item-action-container {
    display: flex;
    gap: 10px;

    .item-actions {
      display: flex;
      gap: 4px;

      .action-button {
        background-color: black;
        border: none;
        padding: 0px 5px;
        color: #fff;
        font-weight: 600;
      }
    }
  }
}
.user-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  color: $primary-button-color;
  font-size: 10px;
  font-weight: bold;
  justify-content: center;
  margin-top: 5px;
  padding: 20px;
  .school-logo {
    width: 100px;
    height: auto;
    margin-top:20px;
  }
}

.checkout-container {
  padding: 0px 40px 40px;
  margin: 0px auto 40px;
  max-width: 1200px;
  background-color: #fff;
  border-radius: 10px;

  .checkout-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .checkout-form, .checkout-summary {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .checkout-form {
    flex: 1 1 48%;

    h2 {
      margin-bottom: 20px;
      color: $primary-button-color;
      font-size: 24px;
      border-bottom: 2px solid $primary-button-color;
      padding-bottom: 10px;
    }

    form {
      display: flex;
      flex-direction: column;

      .form-group {
        margin-bottom: 20px;

        input, select {
          width: 100%;
          padding: 12px;
          border: 1px solid #ddd;
          font-size: 16px;
          color: #333;
          transition: border-color 0.3s;

          &:focus {
            border-color: #007bff;
            outline: none;
          }
        }
      }


      .phoneinput {
        margin-bottom: 20px;
        width:80%;
       
    

        input, select {
          width: 100%;
          padding: 12px;
          border: 1px solid #ddd;
          font-size: 16px;
          color: #333;
          transition: border-color 0.3s;

          &:focus {
            border-color: #007bff;
            outline: none;
          }
        }
      }

      

      .form-row {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .form-group {
          flex: 1 1 calc(50% - 10px);
        }
      }
div{
  .AcceptUI{
    width:  -webkit-fill-available;
    padding: 15px 20px;
    background-color: darken($primary-button-color, 10%);;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-top: 10px;

    &:hover {
      background-color: darken($primary-button-color, 10%);
    }
  }
}
      .submit-button {
        width: 100%;
        padding: 15px 20px;
        background-color: $primary-button-color;
        color: white;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 16px;
        transition: background-color 0.3s;
        margin-top: 10px;

        &:hover {
          background-color: darken($primary-button-color, 10%);
        }
      }
      .checkout-button {
        width: 100%;
        padding: 15px 20px;
        background-color: #0e8d45;
        color: white;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 16px;
        transition: background-color 0.3s;
        margin-top: 10px;

       
      }
    }
  }

  .checkout-summary {
    flex: 1 1 48%;

    h2 {
      margin-bottom: 20px;
      color: $secondary-text-color;
      font-size: 24px;
      border-bottom: 2px solid $secondary-text-color;
      padding-bottom: 10px;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        padding: 10px 0;
        border-bottom: 1px solid #eee;

        .item-info {
          display: flex;
          align-items: center;

          img {
            width: 60px;
            height: 60px;
            margin-right: 15px;
            border-radius: 6px;
            border: 1px solid #ddd;
          }

          h3 {
            margin: 0;
            font-size: 18px;
            color: #333;
          }

          p {
            margin: 0;
            font-size: 14px;
            color: #666;
          }
        }

        .item-action-container {
          display: flex;
          gap: 10px;

          .item-actions {
            display: flex;
            align-items: center;
            gap: 4px;
            column-gap: 10px;
            margin-right: 50px;

            .action-button {
              background-color: black;
              border: none;
              padding: 0px 5px;
              color: #fff;
              font-weight: 600;
            }
          }
       
          
          .remove-margin{
           
            @include media-breakpoint-down('sm') {
              margin-right: 0px;
            }
          }
          .item-price{
            text-align: end;
            width: 80px;
          }
          .remove-width{
           
            @include media-breakpoint-down('sm') {
              width: auto;
            }
          }
        }
      }
    }

    .coupon-form {
      display: flex;
      margin-top: 20px;

      input {
        flex: 1;
        padding: 12px;
        border: 1px solid #ddd;
        border-radius: 6px 0 0 6px;
        font-size: 16px;
        color: #333;
      }

      button {
        padding: 12px 20px;
        background-color: $primary-button-color;
        color: white;
        border: none;
        border-radius: 0 6px 6px 0;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s;

        &:hover {
          background-color: darken($primary-button-color, 10%);
        }
      }
    }

    .total, .subtotal, .shipping, .grand-total, .donation {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      margin-inline-end: 0px;

      p {
        margin: 0;
        font-size: 16px;
      }
    }

    .total h3, .grand-total p {
      font-size: 24px;
      font-weight: 700;
      color: #333;
    }
    .form-student {
      margin-bottom: 20px;

      input, select {
        width: 100%;
        padding: 12px;
        border: 1px solid #ddd;
        font-size: 16px;
        color: #333;
        transition: border-color 0.3s;

        &:focus {
          border-color: #007bff;
          outline: none;
        }
      }
    }
    .student-title{
      margin-top: 20px;
      padding-top: 30px;
    }
    
    .student-subtitle {
      font-size: 14px;
    }


    .donation-input {
      width: 100px;
      padding: 5px;
      border: 1px solid #ddd;
      font-size: 16px;
      color: #333;
      transition: border-color 0.3s;
      text-align: right;

      &:focus {
        border-color: #007bff;
        outline: none;
      }
    }
  }

  @include media-breakpoint-down('lg') {
    .checkout-content {
      flex-direction: column;
    }

    .checkout-form, .checkout-summary {
      width: 100%;
      padding: 20px;
    }
  }

  @include media-breakpoint-down('md') {
    .checkout-form, .checkout-summary {
      padding: 15px;
    }
  }

  @include media-breakpoint-down('sm') {
    .checkout-form, .checkout-summary {
      padding: 10px;
    }
  }
}
