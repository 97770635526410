@import '_mixins';
.ant-input-textarea-show-count .ant-input-data-count{
  bottom: 10px;
  right: 4px;
}
.update-user-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 25px;
 
  @include media-breakpoint-down(md) {
    padding: 15px;
  }

  @include media-breakpoint-down(sm) {
    padding: 10px;
  }
}

.profile-container {
  display: flex;
  align-items: flex-start;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
}

.profile-image-wrapper {
  position: relative;
  width: 120px;
  height: 120px;
  margin-right: 40px;
  

  // Maintain the aspect ratio to keep the image circular
  aspect-ratio: 1 / 1;

  @include media-breakpoint-down(md) {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .profile-image {
    border-radius: 50%;
    object-fit: cover;
    border-radius: 50%;
  }

  .edit-icon-overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #af0606;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      width: 25px;
      height: 25px;
    }

    svg {
      color: #fff;
      font-size: 18px;

      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }
    }
  }
}

.form-content {

  flex-grow: 1;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;

  @include media-breakpoint-down(md) {
    width: 90%;
    grid-template-columns: 1fr;
    gap: 15px;
  }

  @include media-breakpoint-down(sm) {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 15px;  // Maintain the gap between form items in small screens
  }

  .form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    color: #232323;

    label {
      margin-bottom: 5px;
      font-weight: 500;
    }

    input,
    textarea,

    .ant-input {
   
      width: 100%;
      border-radius: 15px;
      padding: 7px 11px;
      border: 1px solid #d9d9d9;
      transition: border-color 0.3s;
      color: #718ebf;

      &:focus,
      &:active,
      &.ant-input-focused {
        border-color: red !important;
        box-shadow: none;
      }

      @include media-breakpoint-down(sm) {
        padding: 5px 8px;
      }
    }
  }

  .full-width {
    grid-column: span 2;

    @include media-breakpoint-down(md) {
      grid-column: span 1;
    }
  }

  .leaderboard-toggle {
    .ant-switch {
      width: 50px;

      @include media-breakpoint-down(sm) {
        width: 40px;
      }
    }
  }

  .submit-button-container {
    grid-column: span 2;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    @include media-breakpoint-down(md) {
      justify-content: center;
    }

    .red-button {
      width: 150px;
      height: 40px;
      font-size: 16px;
      border-color: #af0606;

      @include media-breakpoint-down(sm) {
        width: 120px;
        height: 35px;
        font-size: 14px;
      }
    }
  }
}
