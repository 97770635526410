@mixin media-breakpoint-down($breakpoint) {
  @if $breakpoint == 's' {
    @media (max-width: 470px) { @content; }
  } @else if $breakpoint == 'md' {
    @media (max-width: 768px) { @content; }
  } @else if $breakpoint == 'lg' {
    @media (max-width: 992px) { @content; }
  } @else if $breakpoint == 'xl' {
    @media (max-width: 1200px) { @content; }
  }
}
.user-cards{
    display: flex;
    flex-direction: row;
    gap: 15px;
    .ant-card{
      .ant-card-body{
        @include media-breakpoint-down(md) {
         
          padding: 12px;
        }
        @media(max-width: 629px){
          padding: 24px;
        }
        @media(max-width: 392px){
          padding: 8px;
        }
      }
    
      .ant-card-head{
        @include media-breakpoint-down(md) {
         
          padding: 12px;
        }
        @media(max-width: 392px){
          padding: 2px;
        }
      }
    }
  
      @media(max-width: 629px){
        flex-direction: column;
      }
}