.add-product-container {
  .ant-switch{
    margin: 0px 12px;
  }
  .ant-modal{
    .ant-modal-content{
      background-color: #f8f8f8;
     .ant-modal-header{
      background-color: #f8f8f8;
     }
    }
  }
 margin-top: 60px;
  padding: 16px 0px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  .ant-typography{
    border-bottom: 1px solid #E9EAF0;
    margin-bottom: 0px;
    padding-bottom: 16px;
    padding-left: 20px;
    padding-right: 20px;
    margin-block-start: 0px;
    .editProduct{
      margin-top: 0px;
      font-weight: "medium";
    }
  }
  .ant-list-item{
    width: 100%;
  }
  :where(.css-dev-only-do-not-override-3mkzbg).ant-modal .ant-modal-title{
    font-size: 24px;
    font-weight: 500;
  }
}

.add-product-form {
  display: flex;
  padding: 10px 40px;
  flex-direction: column;
  .currencyInput{
    border: 1px solid #d9d9d9;
    padding: 16px;
    width: 100%;
  }
}

.ant-modal-title{
  font-size: 24px;
  font-weight: 500;
}
.currencyInputCatalog{
  border: 1px solid #d9d9d9;
  padding: 8px;
}
