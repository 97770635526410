.product-details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  border-radius: 10px;
  max-width: 1000px;
  margin: auto;
  background-color: #fff;
  overflow: hidden;

  
  .product-image {
    flex: 1;
    max-width: 400px;
    border-radius: 10px;
    margin-right: 20px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .product-details-content {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;

    h1 {
      font-size: 2em;
      margin-bottom: 15px;
      color: #333;
      text-align: center;
    }

    .product-price {
      font-size: 1.5em;
      color: black;
      margin-bottom: 20px;
      text-align: center;
    }

    p {
      font-size: 1.1em;
      margin-bottom: 15px;
      color: #555;
      line-height: 1.5;
      text-align: center;
    }

    .product-weight {
      font-weight: bold;
      text-align: center;
    }

    .quantity-actions {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      button {
        padding: 10px 15px;
        border: none;
        border-radius: 5px;
        background-color: #e74c3c;
        color: white;
        font-size: 1.2em;
        cursor: pointer;
        margin: 0 10px;
        transition: background-color 0.3s, transform 0.2s;

        &:hover {
          background-color: #c0392b;
          transform: scale(1.1);
        }
      }

      .quantity {
        font-size: 1.5em;
        font-weight: bold;
        margin: 0 10px;
        min-width: 40px;
        text-align: center;
      }
    }

    .button-container {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .add-to-cart {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background-color:black;
        color: white;
        font-size: 1.2em;
        cursor: pointer;
        transition: background-color 0.3s, transform 0.2s;

        &:hover {
          background-color: #2aba34;
          transform: scale(1.1);
        }
      }
    }
  }
}
