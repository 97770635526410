$form-background-color: #f8f8f8;
$form-border-color: #e1e1e1;
$input-border-color: #ccc;
$input-focus-border-color: #007bff;
$button-background-color: #007bff;
$button-hover-background-color: #0056b3;
$error-color: #d9534f;
$font-family: 'Arial, sans-serif';

.credit-card-form {
  background-color: $form-background-color;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  margin: 0 auto;
  font-family: $font-family;

  .form-group {
    margin-bottom: 15px;
    position: relative;

    input {
      width: 100%;
      padding: 10px;
      padding-right: 40px; // space for the icon
      border: 1px solid $input-border-color;
      border-radius: 3px;
      font-size: 16px;
      transition: border-color 0.3s;

      &:focus {
        border-color: $input-focus-border-color;
        outline: none;
      }
    }
.icon-translate{
    position: absolute;
    right: 10px;
    top: 20%;
    transform: translateY(-20%);
    font-size: 18px;
    color: $input-border-color;
}
    .icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 18px;
      color: $input-border-color;
    }

    .error {
      color: $error-color;
      font-size: 14px;
      margin-top: 5px;
    }
  }

  button {
    width: 100%;
    padding: 10px;
    background-color: $button-background-color;
    border: none;
    border-radius: 3px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: $button-hover-background-color;
    }

    &:disabled {
      background-color: lighten($button-background-color, 20%);
      cursor: not-allowed;
    }
  }
}
