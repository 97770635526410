@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import "_variables";
@import '_mixins';

.header-container {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  z-index: 1000;
  width: 100%;
  background-color: #fff; // Adjust background color as needed
}

.header {
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: relative;

  .logo {
    height: 50px; // Adjust size as needed
  }

  .nav {
    display: flex;
    align-items: center;

    .shop-button {
      display: flex;
      align-items: center;
      background-color: #000; // Button background color
      color: white; // Button text color
      padding: 10px 20px;
      border-radius: 8px; // Border radius adjustment
      text-decoration: none; // Remove underline from Link
      font-size: 14px;
      position: relative;

      .shop-text {
        margin-left: 10px;
      }

      .count {
        display: flex;
        color: #000;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        position: absolute;
        left: 15px;
        top: -10px; // Positioning count a bit higher
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background-color: #fff;
      }

      .cart-icon {
        display: flex;
        color: #fff;
        font-size: 20px; // Adjust icon size if needed
        margin-left: 10px; // Adjust the positioning to avoid overlap
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .header {
    .nav {
      .shop-button {
        padding: 8px 16px; // Adjust padding for mobile view
        border-radius: 8px; // Ensure border radius is consistent

        .shop-text {
          display: none; // Hide text on mobile view
        }

        .count {
          top: -12px; // Adjust position to go a little higher
          left: 12px; // Adjust position if necessary
        }

        .cart-icon {
          font-size: 18px; // Make the cart icon a bit smaller on mobile
        }
      }
    }
  }
}
