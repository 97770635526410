@import '_variables';
@import '_mixins';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 90%;
  max-width: 600px;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Product Details Content in Modal */
.product-details-modal {
 
    max-height: 550px; /* Adjust the height as needed */
    overflow-y: auto;
    margin-bottom: 16px;
    padding-right: 8px; /* Space for scrollbar */
    &.scrollable {
      overflow-y: auto;
    }
 

  h1 {
    font-size: 1.8em;
    margin-bottom: 15px;
    color: #333;
    text-align: center;
  }

  .product-price-modal {
    font-size: 1.5em;
    color: black;
    margin-bottom: 20px;
    text-align: center;
  }

  .product-description {
    font-size: 1.1em;
    margin-bottom: 15px;
    color: #555;
    text-align: center;
    line-height: 1.5;
  }

  .button-container-modal {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .add-to-cart {
      padding: 10px 30px;
      border: none;
      border-radius: 5px;
      background-color: #3ce745;
      color: white;
      font-size: 1.2em;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #2aba34;
      }
    }
  }
}

/* Additional Styling for Modal Images */
.product-image-modal {
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  margin-bottom: 20px;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Media Breakpoints */
@include media-breakpoint-down('md') {
  .modal-content {
    padding: 15px;
  }

  .product-details-modal {
    h1 {
      font-size: 1.6em;
    }

    .product-price-modal {
      font-size: 1.3em;
    }

    .product-description {
      font-size: 1em;
    }

    .button-container-modal {
      .add-to-cart {
        font-size: 1em;
        padding: 8px 20px;
      }
    }
  }

  .product-image-modal {
    max-width: 250px;
  }
}

@include media-breakpoint-down('sm') {
  .product-details-modal {
    h1 {
      font-size: 1.4em;
    }

    .product-price-modal {
      font-size: 1.2em;
    }

    .product-description {
      font-size: 0.9em;
    }

    .button-container-modal {
      .add-to-cart {
        font-size: 0.9em;
        padding: 6px 15px;
      }
    }
  }

  .product-image-modal {
    max-width: 200px;
  }
}
