// components/NotFound.module.scss
.notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    padding: 0 20px;
  
    .title {
      font-size: 2rem;
      margin-bottom: 1rem;
      color: #C31532;
    }
  
    .message {
      font-size: 1.2rem;
      color: #555;
    }
  }
  
  @media (min-width: 768px) {
    .notFound {
      .title {
        font-size: 3rem;
      }
  
      .message {
        font-size: 1.5rem;
      }
    }
  }
  