.campaign-card {
  border-radius: 25px;
  color: white;
  margin: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 350px;
  // height: 240px;
  box-sizing: border-box;
  position: relative;

  &.active {
    background-color: #ffffff;
  }

  &.closed {
    background-color: #ffffff;
    color: black;
  }

  .ant-card-body {
    .ant-card-meta {
      .ant-card-meta-detail {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;

        .ant-card-meta-description {
          font-size: 12px;
          font-family: 'Roboto';
          font-weight: 400;
          color:  #626879;
          ; /* Inherit color from .campaign-card */
        }

        .ant-card-meta-title {
          font-size: 12px;
          font-weight: 600;
          font-family: 'Roboto';
          color:  #626879;
          margin-bottom: 0px;
          margin-left: 10px
          ; /* Inherit color from .campaign-card */
        }
      }
    }
  }
 
  .details-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    .details-text{
      font-size: 18px;
      font-weight: 600;
    }

    // &.active {
    //   .ant-btn-primary {
    //     background-color: white;
    //     color: #d9534f;
    //     font-weight: 600;
    //     border: none;

    //     &:hover {
    //       background-color: #f1f1f1;
    //     }
    //   }
    // }

    // &.closed {
    //   .ant-btn-primary {
    //     background-color: black;
    //     font-weight: 600;
    //     color: white;
    //     border: none;

    //     &:hover {
    //       background-color: #333; /* Darker shade for hover */
    //     }
    //   }
    // }
  }
}
:where(.css-dev-only-do-not-override-kqn9x1).ant-card .ant-card-meta-detail >div:not(:last-child){
  margin-bottom: 0px;
  margin-left: 4px;
}