.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f0f2f5;
  
    .logo-container {
      margin-bottom: 20px;
  
      .fundraising-logo {
        max-width: 200px;
      }
    }
  
    .button-container {
      margin-top: 20px;
    }
  }
  