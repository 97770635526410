html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: border-box;
  font-family: Graphik Web, sans-serif, Lato, Roboto, Helvetica, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ant-typography {
  font-family: 'Inter', sans-serif;
}
.ant-btn{
  padding: 24px;
}
:where(.css-dev-only-do-not-override-zd39c6).ant-menu .ant-menu-item, :where(.css-dev-only-do-not-override-zd39c6).ant-menu .ant-menu-submenu, :where(.css-dev-only-do-not-override-zd39c6).ant-menu .ant-menu-submenu-title{
  border-radius: 0px;
}
:where(.css-dev-only-do-not-override-zd39c6).ant-menu-inline .ant-menu-item, :where(.css-dev-only-do-not-override-zd39c6).ant-menu-vertical .ant-menu-item, :where(.css-dev-only-do-not-override-zd39c6).ant-menu-inline .ant-menu-submenu-title, :where(.css-dev-only-do-not-override-zd39c6).ant-menu-vertical .ant-menu-submenu-title{
  margin-inline: 0px;
  width: 100%;
  
}
:where(.css-dev-only-do-not-override-zd39c6).ant-menu-light .ant-menu-item-selected, :where(.css-dev-only-do-not-override-zd39c6).ant-menu-light>.ant-menu .ant-menu-item-selected{
  border-right: 3px solid #C31532;
}
textarea:where(.css-dev-only-do-not-override-zd39c6).ant-input{
  line-height: 1.34;
}
