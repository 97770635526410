.ant-btn{
  border-radius: 4px;
}
  
  .share-buttons h3 {
    margin-bottom: 10px;
    text-align: center;
  }
  
  .share-buttons-container {
    display: flex;
    gap: 10px;
  }
  
  .share-button {
    display: inline-block;
    cursor: pointer;
  }
  .share-button-form{
    input, select {
      width: 100%;
      padding: 12px;
      border: 1px solid #ddd;
      font-size: 16px;
      color: #333;
      transition: border-color 0.3s;
  
      &:focus {
        border-color: #007bff;
        outline: none;
      }
    }
  }
  