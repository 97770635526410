@mixin media-breakpoint-down($breakpoint) {
  @if $breakpoint == 'sm' {
    @media (max-width: 576px) { @content; }
  } @else if $breakpoint == 'md' {
    @media (max-width: 768px) { @content; }
  } @else if $breakpoint == 'lg' {
    @media (max-width: 992px) { @content; }
  } @else if $breakpoint == 'xl' {
    @media (max-width: 1200px) { @content; }
  }
}

.shipping-options-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;

  .option,
  .option-donation {
    height: 50px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: center;
    font-size: 16px;

    font-weight: bold;


  }

  .option-active {
    background-color: #eef0f1;
    color: #000;
    border: 1px solid #000;
  }

  .icon {
    margin-right: 8px;
  }

  @include media-breakpoint-down('xl') {
    .option,
    .option-donation {
      width: 180px;
      height: 45px;
      font-size: 14px;
    }
  }

  @include media-breakpoint-down('lg') {
    .option,
    .option-donation {
      width: 160px;
      height: 40px;
      font-size: 12px;
    }
  }

  @include media-breakpoint-down('md') {
    
    gap: 15px;

    .option,
    .option-donation {
      width: 140px;
      height: 35px;
      font-size: 12px;
    }
  }

  @include media-breakpoint-down('sm') {
    .option,
    .option-donation {
      width: 120px;
      height: 30px;
      font-size: 10px;
    }
  }
}
