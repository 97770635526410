@import '_mixins';
.csv-button{
    @include media-breakpoint-down(md) {
        width: calc(100vw - 75px);
    }

  
}
.campaign-detail{
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
   
    @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 16px;
    }
    .button-container{
       
        margin: 10px 10px 10px 0px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        @include media-breakpoint-down(md) {
            width:140px;
        }
        .button{
            width: 160px;
            padding: 7px 11px;
            font-size: 16px;
            font-weight: 600;
            height: auto;
        }
    }
    .card-container{
        display: flex;
    flex-direction: row;
    gap: 16px;
        .ant-card-body {
            .ant-card-meta {
                align-items: center;
                .ant-card-meta-avatar{
                    @include media-breakpoint-down(md) {
                        width: 60px;
                        height: 60px;
                    }
                 
                    .icon-container {
                        display: flex;
                        width: 65px;
                        height: 65px;
                        padding:25px;
                        border-radius: 50px;
                        justify-content: center;
                        background-color: #FFF5D9;
                        align-items: center;
                        @include media-breakpoint-down(md) {
                            width: 20px;
                            height: 20px;
                        }
                        @include media-breakpoint-down(sm) {
                            width: 5px;
                            height: 5px;
                            padding:25px;
                            
                        }
                      }
                      .purple{
                        background-color: #E7EDFF;
                      }
                      .icon-circle {
                       
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      }
                      
                      .icon-circle .fa-money-bag {
                        font-size: 40px; /* Adjust icon size as needed */
                        color: #f4b740; /* Icon color */
                        @include media-breakpoint-down(md) {
                            font-size: 20px; 
                            
                        }
                      }
                }
              .ant-card-meta-detail {
                display: flex;
                flex-direction: column-reverse;
               
                  
                .ant-card-meta-description {
                    font-size: 14px;
                    font-family: 'Roboto';
                    color: rgb(172, 171, 171); /* Inherit color from .campaign-card */
                  }
          
                  .ant-card-meta-title {
                    margin-bottom: 0px;
                    font-family: 'Roboto';
                    color: inherit; /* Inherit color from .campaign-card */
                  }
              }
            }
        } 
    }
}
.campaign-contacts{

    .ant-card-body {
        .ant-card-meta {
            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
            .ant-card-meta-avatar{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 20px;
                .ant-card-meta-detail{
                    display: flex;
flex-direction: column-reverse;
                 
                }
            }
            .ant-card-meta-detail{
                .ant-card-meta-title{
                    display: flex;
                    gap: 16px;
                    @include media-breakpoint-down(md) {
                        flex-direction: column;
                        text-align: center;
                    }
                    .button{
                        width: 170px;
                        padding: 9px 11px;
                        height: auto;
                        @include media-breakpoint-down(md) {
                            width: 170px;
                        }
                    }
                }
            }
        }}
}