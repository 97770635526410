.add-user-container {
  margin-top: 60px;
  padding: 16px 0px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  .ant-typography{
    border-bottom: 1px solid #E9EAF0;
    margin-bottom: 0px;
    padding-bottom: 16px;
    padding-left: 20px;
    padding-right: 20px;
    margin-block-start: 0px;
    .editProduct{
      margin-top: 0px;
      font-weight: "medium";
    }
  }
  }
  
  .add-user-form {
    display: flex;
    padding: 10px 40px;
    flex-direction: column;
    :where(.css-dev-only-do-not-override-3mkzbg).ant-input-group-wrapper-outlined .ant-input-group-addon{
      background: none;
    }
    :where(.css-dev-only-do-not-override-3mkzbg).ant-qrcode{
      border: none;
    }
    .currencyInput{
      width: 100%;
      border: 1px solid #d9d9d9;
      padding: 16px;
      font-size: 16px;
      height: 48px;
    }
    .flexRow{
      .ant-form-item{
        width: 47%;
      }
    }
    .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector{
      padding: 16px;
      border-radius: 0px;
      font-size: 16px;
      height: 48px;
    }
  }
 

  

  
  
  
 

  
  .add-user-form .ant-list {
    width: 100%;
  }
  
  .add-user-form .ant-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
  }
  
  