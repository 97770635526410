.ant-modal {
  .ant-modal-content {
    background-color: #f8f8f8;
    .ant-modal-header {
      background-color: #f8f8f8;
    }
  }
}

.item-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 10px 0;
  border-bottom: 1px solid #eee;

  .item-actions {
    display: flex;
    align-items: center;
    gap: 5px; // Reduced spacing between buttons and quantity
    margin-right: 15px; // Added margin to move the buttons more to the left

    .action-button {
      background-color: black;
      border: none;
      padding: 0px 5px;
      color: #fff;
      font-weight: 600;
    }

    .quantity {
      margin: 0 5px; // Reduced spacing around the quantity
    }
  }

  .item-price {
    width:70px;
    margin-left: 10px; // Spacing between the quantity and unit price
    font-size: 16px;
    color: #333;
    align-self: center; // Ensures the price aligns with the buttons
  }

  .item-info {
    display: flex;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
      margin-right: 15px;
      border-radius: 6px;
      border: 1px solid #ddd;
    }

    h3 {
      margin: 0;
      font-size: 18px;
      color: #333;
    }

    p {
      margin: 0;
      font-size: 14px;
      color: #666;
    }
  }

  .total-price {
    font-size: 16px;
    color: #333;
  }
}
